<template>
  <div class="home container-fluid">
    <Header />
    <div class="container mt-3" v-if="type != 3 && type != 4">
      <!-- <h1 @click="exportToPDF()">HOME</h1>  -->
      <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">รายชื่อ</h5>
                    </div>
                    <div class="char float-right">
                      <span> {{ fileupload }} </span>
                      <button class="btn btn-outline-info" onclick="document.getElementById('getFile').click()">Import from excel <i class="far fa-file-excel"></i></button>
                      <input type="file" id="getFile" @change="onChange($event)" style="display:none">
                      <!-- <button class="btn btn-outline-primary btn-add" @click="onAdd('add')" data-bs-toggle="modal" data-bs-target="#cardModal" type="button">Add <i class="far fa-plus-square"></i></button> -->
                      <button class="btn btn-outline-secondary btn-add" data-bs-toggle="modal" data-bs-target="#rankModal" type="button">Generate Number <i class="far fa-address-card"></i></button>
                      <button class="btn btn-outline-success btn-add" @click="onGenerateMany()">Generate All <i class="far fa-address-card"></i></button>
                      <download-excel
                            class="btn btn-outline-info btn-add "
                            :data="cardList"
                            :fields="json_fields"
                            worksheet="My Worksheet"
                            :name="'export_from_db.xls'"
                            >
                            Export to Excel
                        </download-excel>
                      <!-- <button class="btn btn-outline-danger btn-add" @click="onDeleteAll()">Delete All <i class="far fa-trash-alt"></i></button> -->
                    </div>
      </div>

      <div class="modal fade" id="rankModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">แบบฟอร์มบัตรติดหน้าอก</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto p-1">
                  <input type="number" v-model="rank.start" class="form-control" placeholder="ลำดับเริ่มต้น" aria-label="First name">
                </div>
                <div class="col-auto p-1">
                  <input type="number" v-model="rank.end" class="form-control" placeholder="ลำดับสุดท้าย" aria-label="Last name">
                </div>
                <div class="col-auto p-1">
                  <input type="text" v-model="rank.faculty" class="form-control" placeholder="คณะ/สังกัด" aria-label="Last name">
                </div>
                <div class="col-auto p-1">
                  <input type="text" v-model="rank.date" class="form-control" placeholder="วันที่ (ตัวอย่าง ๑๗ ม.ค. ๖๕)" aria-label="Last name">
                </div>
                <div class="col-auto p-1">
                  <input type="text" v-model="rank.cardtype" class="form-control" placeholder="ประเภทบัตร" aria-label="Last name">
                </div>
                <div class="col-auto p-1">
                  <button type="button" class="btn btn-outline-success" @click="generateRank()" data-bs-dismiss="modal" aria-label="Close">Generate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="cardModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">แบบฟอร์มบัตรติดหน้าอก</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                  <div class="mb-3">
                      <label class="form-label">คณะ/สังกัด</label>
                      <select v-model="form.department" class="form-control">
                        <option value="ผู้เข้าร่วมพิธีฯ">ผู้เข้าร่วมพิธีฯ</option>
                        <option value="ผู้ปฏิบัติหน้าที่">ผู้ปฏิบัติหน้าที่</option>
                      </select>
                      <!-- <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.department"> -->
                      <label v-if="type == 1 || type == 2" class="form-label mt-2">รูปถ่าย</label><br>
                      <img v-if="form.image" width="90" height="90" :src="form.image" alt="avatar">
                      <input v-if="type == 1 || type == 2" type="file" id="getFile2" @change="onChange2($event)"><br>
                      <label class="form-label mt-2">คำนำหน้าชื่อ</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.prename">
                      <label class="form-label mt-2">ชื่อ - นามสกุล</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.fullname">
                      <label class="form-label mt-2">หน้าที่</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.faculty">
                      <label class="form-label mt-2">เลขบัตรประชาชน</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.personalid">
                      <label class="form-label mt-2">วันที่</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.date">
                      <label class="form-label mt-2">ประเภท</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.cardtype">
                      <label class="form-label mt-2">ลำดับ</label>
                      <input type="text" class="form-control" placeholder="กรุณากรอกข้อมูล" v-model="form.runnumber">
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="onSave()" data-bs-dismiss="modal">Save</button>
            </div>
          </div>
        </div>
      </div>

      <div class="progress" v-if="p_status">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="progress"></div>
      </div>
      <!-- <select class="form-control mb-3" style="max-width: 250px;" @change="changeFaculty($event.target.value)">
        <option value="all">ทั้งหมด</option>
        <option v-for="item in selectFaculty" :key="item._id" :value="item._id"> {{ item._id }} </option>
      </select> -->
      <div class="form-group row" style="margin-left: 1px;">
        <input type="number" style="width:200px;" class="form-control" v-model="genRank.start" placeholder="ลำดับเริ่มต้น">
        <input type="number" style="width:200px; margin-left: 5px;" v-model="genRank.end" class="form-control" placeholder="ลำดับสุดท้าย">
        <button class="btn btn-outline-info" style="width:100px; margin-left: 5px;" type="button" @click="onGenerateRank()"> Generate </button>
      </div>
      <br>
                <table id="tb-card" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">ฝ่าย</th>
                        <th scope="col">ชื่อ-นามสกุล</th>
                        <th scope="col">ลำดับ</th>
                        <th scope="col" class="text-center">จัดการข้อมูล</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cardList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.department }} </td>
                            <td> {{ item.prename }} {{ item.firstname }} {{ item.lastname }} </td>
                            <td> {{ item.runnumber }} </td>
                            <td class="text-center">
                              <button @click="onUpdate(item)" class="btn btn-outline-info btn-sm" data-bs-toggle="modal" data-bs-target="#cardModal" type="button"><i class="fas fa-pencil-alt"></i></button>
                              <button @click="onDelete(item._id)" class="btn btn-outline-danger btn-sm btn-add"><i class="fas fa-trash-alt"></i></button>
                              <button @click="onGenerateOne(item)" class="btn btn-outline-secondary btn-sm btn-add"><i class="far fa-address-card"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
    </div>

    <div class="container mt-3" v-if="type == 3 || type == 4">
      <div class="row">
        <div class="col-auto">
          <input type="number" v-model="rank.start" class="form-control" placeholder="ลำดับเริ่มต้น" aria-label="First name">
        </div>
        <div class="col-auto">
          <input type="number" v-model="rank.end" class="form-control" placeholder="ลำดับสุดท้าย" aria-label="Last name">
        </div>
        <div class="col-auto">
          <input type="text" v-model="rank.faculty" class="form-control" placeholder="คณะ/สังกัด" aria-label="Last name">
        </div>
        <div class="col-auto">
          <input type="text" v-model="rank.date" class="form-control" placeholder="วันที่ (ตัวอย่าง ๑๗ ม.ค. ๖๕)" aria-label="Last name">
        </div>
        <div class="col-auto">
          <input type="text" v-model="rank.cardtype" class="form-control" placeholder="ประเภทบัตร" aria-label="Last name">
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-outline-success" @click="generateRank()">Generate</button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import Header from '@/components/Card/Header';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      data: '',
      dataimage: [],
      permission: '',
      type: '',
      fileupload: '',
      progress: 'width: 0%',
      p_status: false,
      cardList: [],
      form: {
        department: '',
        prename: '',
        firstname: '',
        lastname: '',
        personalid: '',
        date: '',
        cardtype: '',
        runnumber: '',
        image: '',
        faculty: ''
      },
      status: '',
      selectFaculty: '',
      selectF: 'all',
      rank:{
        start: '',
        end: '',
        faculty: '',
        date: '',
        cardtype: ''
      },
      genRank:{
        start: '',
        end: ''
      },
      json_fields: {
      "ลำดับ": "runnumber",
      "คำนำหน้าชื่อ": "prename",
      "ชื่อ-นามสกุล": "fullname",
      "หมายเลขบัตรประชาชน": "personalid",
      "สีบัตร": "type",
      "บทบาทหน้าที่": "department",
      "หมายเหตุ": "faculty",
    },
    }
  },
  mounted() {
    this.onInit();

    // $('#cardModal').on('hidden.bs.modal', function () {
    //   this.form.department = '';
    //   this.form.firstname = '';
    //   this.form.lastname = '';
    //   this.form.date = '';
    //   this.form.cardtype = '';
    //   this.form.runnumber = '';
    //   this.form.image = '';
    // })
  },
  methods: {
    onInit(){
      if (this.$route.params.redirect) {
        window.location.reload();
      }
      this.type = this.$route.params.type;
      // axios.get('/api/user').then(res => { 
      //   this.permission = res.data.permission;

          axios.get(`/api/cardlist/${this.type}`).then(res => {
            this.cardList = res.data;
            $(document).ready(function() {
                $('#tb-card').DataTable({
                    'pageLength': 25,
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    columnDefs: [
                        { orderable: false, targets: -1 }
                    ],
                    "language": {
                        "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                        "zeroRecords": "ไม่พบข้อมูล!",
                        "info": "หน้า _PAGE_ จาก _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "paginate": {
                            "previous": "ก่อนหน้า",
                            "next": "ต่อไป"
                        },
                        "search": "ค้นหา"
                    },
                     "bDestroy": true
                });
            } );
          });
      // });

       axios.get(`/api/cardlist/selected/${this.type}`).then(res => {
         this.selectFaculty = res.data;
       });
    },
    exportToPDF() {
      this.$router.push({ name: 'view', params: this.data });
      // html2pdf(this.$refs.document, {
      //   margin: 1,
      //   filename: "document.pdf",
      //   image: { type: "png", quality: 0.98 },
      //   html2canvas: { dpi: 192, letterRendering: true },
      //   jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      // });
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      this.fileupload = event.target.files[0].name;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          this.data = XLSX.utils.sheet_to_json(ws, { raw:true });

          // this.exportToPDF();

          this.data.forEach(element => {
            element.permission = this.permission;
            element.type = this.type;
          });

          // this.p_status = true;  
          
          axios.post('/api/cardlist', this.data, {
            onUploadProgress: progressEvent => {
              // this.progress = 'width: ' + Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%';
            }
          }).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Import Success',
              showConfirmButton: false,
              timer: 1500
            })
            
            $('#tb-card').DataTable().destroy();
            this.onInit();
            
          })
        }

        reader.readAsBinaryString(this.file);
      }
    },
    onChangeMultiple(event){
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(event);
        reader.onload = function() {
          resolve(reader.result);
        };
      });
    },
    async SaveImage(event){
      for (var i = 0; i < event.target.files.length; i++) {
        let fileData = await this.onChangeMultiple(event.target.files[i]);
        this.dataimage.push({
          personalid: event.target.files[i].name.split('.')[0],
          image: fileData
        });
      }

      // axios.put('/api/cardlist/image/1', this.dataimage).then(res => {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'Import Success',
      //         showConfirmButton: false,
      //         timer: 1500
      //       })
      //     })

    },
    onChange2(event) {
      this.file2 = event.target.files ? event.target.files[0] : null;
      if (this.file2) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file2)
        reader.onload = () => {
            this.form.image = reader.result;
        };
      }
    },
    getCardList(){
      const url = '/api/cardlist/'+ this.type + '/' + this.permission;
      axios.get(url).then(res => {
        this.cardList = res.data;
        $(document).ready(function() {
            $('#tb-card').DataTable({
                'pageLength': 25,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                "bDestroy": true,
                columnDefs: [
                    { orderable: false, targets: -1 }
                ],
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
      });
    },
    onAdd(status){
      this.status = status;
    },
    onSave(){
      this.form.type = this.type;
      this.form.permission = this.permission;
      if (this.status == 'add') {
        if (this.form.department != '' && this.form.fullname != '' && this.form.date != '' && this.form.cardtype != '' && this.form.runnumber != '') {
          axios.post('/api/cardlist', this.form).then(res => {
            if(res.data == 'repeat'){
              Swal.fire({
                icon: 'error',
                title: 'มีข้อมูลในระบบแล้ว!',
                showConfirmButton: false,
                timer: 1500
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                showConfirmButton: false,
                timer: 1500
              })
              $('#tb-card').DataTable().destroy();
              this.onInit();   
              this.form.image = '';
            }     
          })
        } else {
          Swal.fire({
                icon: 'error',
                title: 'กรุณากรอกข้อมูลให้ครบ',
                showConfirmButton: false,
                timer: 1500
          })
        }
      } else {
        if (this.form.department != '' && this.form.fullname != '' && this.form.date != '' && this.form.cardtype != '' && this.form.runnumber != '') {
          axios.put(`/api/cardlist/${this.form._id}`, this.form).then(res => {
            if(res.data == 'repeat'){
              Swal.fire({
                icon: 'error',
                title: 'มีข้อมูลในระบบแล้ว!',
                showConfirmButton: false,
                timer: 1500
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                showConfirmButton: false,
                timer: 1500
              })

              $('#tb-card').DataTable().destroy();
              this.onInit();
            }
          })
        } else {
          Swal.fire({
                icon: 'error',
                title: 'กรุณากรอกข้อมูลให้ครบ',
                showConfirmButton: false,
                timer: 1500
          })

          this.form.image = '';
        }
      }
    },
    onUpdate(item){
      this.form._id = item._id;
      this.form.department = item.department;
      this.form.fullname = item.fullname;
      this.form.date = item.date;
      this.form.cardtype = item.cardtype;
      this.form.runnumber = item.runnumber;
      this.form.type = item.type;
      this.form.permission = item.permission;
      this.form.image = item.image;
      this.form.personalid = item.personalid;
      this.form.prename = item.prename;
      this.form.faculty = item.faculty;
    },
    onDelete(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/cardlist/${id}`).then(res => {
              Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            $('#tb-card').DataTable().destroy();
            this.onInit();
          })
        }
      })
    },
    onGenerateOne(item){
      this.$router.push({ name: 'view', params: {
        length: 1,
        info: item
      } });
    },
    onGenerateMany(){
      this.$router.push({ name: 'view', params: {
        info: this.separateArray(this.cardList, 30)
      } });
    },
    onGenerateRank(){
      const data = [];
      
      for (let i = this.genRank.start - 1; i < this.genRank.end; i++) {
        data.push(this.cardList[i]);
      }

      this.$router.push({ name: 'view', params: {
        info: this.separateArray(data, 30)
      } });
    },
    onDeleteAll(){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/cardlist/all/${this.permission}/${this.type}`).then(res => {
              Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            $('#tb-card').DataTable().destroy();
            this.onInit();
          })
        }
      })
    },
    changeFaculty(info){
       axios.get(`/api/cardlist/${this.type}/${this.permission}/${info}`).then(res => {
            this.cardList = res.data;
            $('#tb-card').DataTable().reload();
            $(document).ready(function() {
                $('#tb-card').DataTable({
                    'pageLength': 25,
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    columnDefs: [
                        { orderable: false, targets: -1 }
                    ],
                    "language": {
                        "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                        "zeroRecords": "ไม่พบข้อมูล!",
                        "info": "หน้า _PAGE_ จาก _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "paginate": {
                            "previous": "ก่อนหน้า",
                            "next": "ต่อไป"
                        },
                        "search": "ค้นหา"
                    },
                     "bDestroy": true
                });
            } );
          });
    },
    arobicToThai(num){
      var array = {"1":"๑", "2":"๒", "3":"๓", "4" : "๔", "5" : "๕", "6" : "๖", "7" : "๗", "8" : "๘", "9" : "๙", "0" : "๐"};
      var str = num.toString();
      for (var val in array) {
        str = str.split(val).join(array[val]);
      }
      return str;
    },
    generateRank(){
      var dataRank = [];

      for (let i = Number(this.rank.start); i <= Number(this.rank.end); i++) {
        if (i < 10) {
          dataRank.push({
            runnumber: this.arobicToThai(`000${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else if (i >= 10 && i < 100) {
          dataRank.push({
            runnumber: this.arobicToThai(`00${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else if (i >= 100 && i < 1000) {
          dataRank.push({
            runnumber: this.arobicToThai(`0${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else{
          dataRank.push({
            runnumber: this.arobicToThai(`${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }
      }

      var data = this.separateArray(dataRank, 30);

      this.$router.push({ name: 'view', params: {
        info: data
      } });

    },
    separateArray(arr, size){
      var newArr = [];
      for (var i = 0; i < arr.length; i += size) {
          var sliceIt = arr.slice(i, i + size);
          newArr.push(sliceIt);
      }
      return newArr;
    }
  },
}
</script>

<style scoped>
  .container{
    padding: 5px;
    margin-bottom: 5%;
  }
  .content {
    position: relative;
    text-align: center;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #head-content {
  display: flex;
}

.float-right {
  margin-left: auto;
}

.btn-add{
  margin-left: 5px;
}

</style>
