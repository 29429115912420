<template>
  <div class="container">
      <table>
          <thead>
              <th>name</th>
          </thead>
          <tbody>
              <tr v-for="item in compareList" :key="item._id">
                  <td> {{ item.Text }} </td>
              </tr>
          </tbody>
      </table>
  </div> 
</template>

<script>
import axios from 'axios';
import Header from '@/components/Card/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "home",
  components: {
    Header,
    Footer
  },
  data() {
      return {
          compareList: ''
      }
  },
  methods: {
      
  },
  mounted() {
      axios.get('/api/cardlist').then(res => {
          this.compareList = res.data;
      })
  },
};
</script>

<style scoped>
    
</style>
