<template>
  <div class="container-fluid">
      <div class="row g-0">
        <div class="col-md-6">
            <a href="/external"><img class="center" src="@/assets/img/login_oauth.png" alt="image"></a>
        </div>
        <div class="col-md-6">
            <a href="/cardpage"><img class="center" src="@/assets/img/login_normal.png" alt="image"></a>
        </div>
      </div>
  </div> 
</template>

<script>
import Axios from 'axios';
import Header from '@/components/Card/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "home",
  components: {
    Header,
    Footer
  },
  methods: {
      
  },
};
</script>

<style scoped>
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
</style>
