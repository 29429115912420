<template>
  <div class="home container-fluid">
    <Header />
    <div class="container mt-3">
      <!-- <h1 @click="exportToPDF()">HOME</h1>  -->
      <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">รายชื่อ</h5>
                    </div>
                    <div class="char float-right">
                        <button class="btn btn-outline-success btn-add" @click="onGenerate()">Generate Number <i class="far fa-address-card"></i></button>
                    </div>
      </div>

                <table id="tb-card1" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">ฝ่าย</th>
                        <th scope="col">ชื่อ-นามสกุล</th>
                        <th scope="col">ลำดับ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cardList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.department }} </td>
                            <td> {{ item.fullname }} </td>
                            <td> {{ item.runnumber }} </td>
                        </tr>
                    </tbody>
                </table>
    </div>

    <Footer />
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import Header from '@/components/Card/Header';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      data: '',
      dataimage: [],
      permission: '',
      type: '',
      fileupload: '',
      progress: 'width: 0%',
      p_status: false,
      cardList: '',
      form: {
        department: '',
        fullname: '',
        date: '',
        cardtype: '',
        runnumber: '',
        image: ''
      },
      status: '',
      selectFaculty: '',
      selectF: 'all',
      rank:{
        start: '',
        end: '',
        faculty: '',
        date: '',
        cardtype: ''
      },
      genRank:{
        start: '',
        end: ''
      }
    }
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit(){
      if (this.$route.params.redirect) {
        window.location.reload();
      }
      this.type = this.$route.params.type;
      axios.get('/api/user').then(res => { 
        this.permission = res.data.permission;

          axios.get(`/api/cardlist/${this.type}/${this.permission}/${this.selectF}`).then(res => {
            this.cardList = res.data;
            $(document).ready(function() {
                $('#tb-card1').DataTable({
                    'pageLength': 25,
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    columnDefs: [
                        { orderable: false, targets: -1 }
                    ],
                    "language": {
                        "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                        "zeroRecords": "ไม่พบข้อมูล!",
                        "info": "หน้า _PAGE_ จาก _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "paginate": {
                            "previous": "ก่อนหน้า",
                            "next": "ต่อไป"
                        },
                        "search": "ค้นหา"
                    },
                     "bDestroy": true
                });
            } );
          });
      });

    },
    onGenerate(){
        var data = this.cardList;
        for (let i = 0; i < data.length; i++) {
            if (i < 10) {
                data[i].runnumber = this.arobicToThai(`000${i+1}`);
            }else if (i >= 10 && i < 100) {
                data[i].runnumber = this.arobicToThai(`00${i+1}`);
            }else if (i >= 100 && i < 1000) {
                data[i].runnumber = this.arobicToThai(`0${i+1}`);
            }else{
                data[i].runnumber = this.arobicToThai(`${i+1}`);
            }
        }

        axios.post('/api/cardlist/updatemany', data).then(res => {
            this.onInit();
        })
    },
    arobicToThai(num){
      var array = {"1":"๑", "2":"๒", "3":"๓", "4" : "๔", "5" : "๕", "6" : "๖", "7" : "๗", "8" : "๘", "9" : "๙", "0" : "๐"};
      var str = num.toString();
      for (var val in array) {
        str = str.split(val).join(array[val]);
      }
      return str;
    },
    generateRank(){
      var dataRank = [];

      for (let i = Number(this.rank.start); i <= Number(this.rank.end); i++) {
        if (i < 10) {
          dataRank.push({
            runnumber: this.arobicToThai(`000${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else if (i >= 10 && i < 100) {
          dataRank.push({
            runnumber: this.arobicToThai(`00${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else if (i >= 100 && i < 1000) {
          dataRank.push({
            runnumber: this.arobicToThai(`0${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }else{
          dataRank.push({
            runnumber: this.arobicToThai(`${i}`),
            type: this.type,
            department: this.rank.faculty,
            date: this.rank.date,
            cardtype: this.rank.cardtype
          })
        }
      }

      var data = this.separateArray(dataRank, 30);

      this.$router.push({ name: 'view', params: {
        info: data
      } });

    },
    separateArray(arr, size){
      var newArr = [];
      for (var i = 0; i < arr.length; i += size) {
          var sliceIt = arr.slice(i, i + size);
          newArr.push(sliceIt);
      }
      return newArr;
    }
  },
}
</script>

<style scoped>
  .container{
    padding: 5px;
    margin-bottom: 5%;
  }
  .content {
    position: relative;
    text-align: center;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #head-content {
  display: flex;
}

.float-right {
  margin-left: auto;
}

.btn-add{
  margin-left: 5px;
}

</style>
