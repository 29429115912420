<template>
  <div class="container-fluid">
      <Header />
    <div class="container tabs content mt-5">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" href="#date1" @click="onChangeDate(date1)" data-bs-toggle="tab"> {{ date1 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date2" @click="onChangeDate(date2)" data-bs-toggle="tab"> {{ date2 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date21" @click="onChangeDate(date3)" data-bs-toggle="tab"> {{ date3 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date3" @click="getCompare()" data-bs-toggle="tab"> เปรียบเทียบสถิติ </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date4" @click="getCompareStudent()" data-bs-toggle="tab"> เปรียบเทียบจำนวนคน </a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="date1" class="tab-pane active">
                <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">{{date1}}</h5>
                    </div>
                    <div class="char float-right">
                        <download-excel
                            class="btn btn-outline-info mt-2"
                            :data="merchList"
                            :fields="json_fields"
                            worksheet="My Worksheet"
                            :name="date1 + '.xls'"
                            >
                            Export to Excel
                        </download-excel>
                    </div>
                </div>
                <table id="tb-data" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน</th>
                        <th scope="col">เฉลี่ย (คน/นาที)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.student }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="date2" class="tab-pane">
                <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">{{date2}}</h5>
                    </div>
                    <div class="char float-right">
                        <download-excel
                            class="btn btn-outline-info mt-2"
                            :data="merchList"
                            :fields="json_fields"
                            worksheet="My Worksheet"
                            :name="date2 + '.xls'"
                            >
                            Export to Excel
                        </download-excel>
                    </div>
                </div>
                <table id="tb-data2" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน</th>
                        <th scope="col">เฉลี่ย (คน/นาที)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.student }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="date21" class="tab-pane">
                <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">{{date3}}</h5>
                    </div>
                    <div class="char float-right">
                        <download-excel
                            class="btn btn-outline-info mt-2"
                            :data="merchList"
                            :fields="json_fields"
                            worksheet="My Worksheet"
                            :name="date3 + '.xls'"
                            >
                            Export to Excel
                        </download-excel>
                    </div>
                </div>
                <table id="tb-data21" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน</th>
                        <th scope="col">เฉลี่ย (คน/นาที)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.student }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
             <div id="date3" class="tab-pane">
                <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">เปรียบเทียบสถิติ</h5>
                    </div>
                    <div class="char float-right">
                        <!-- <download-excel
                            class="btn btn-outline-info mt-2"
                            :data="exportCompareList"
                            :fields="json_fields2"
                            worksheet="My Worksheet"
                            name="เปรียบเทียบข้อมูล.xls"
                            >
                            Export to Excel
                        </download-excel> -->
                    </div>
                </div>
                <table id="tb-data3" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">{{date1}}</th>
                        <th scope="col">{{date2}}</th>
                        <th scope="col">{{date3}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in facultyList2" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ day1[index] }} </td>
                            <td> {{ day2[index] }} </td>
                            <td> {{ day3[index] }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="date4" class="tab-pane">
                <div id="head-content">
                    <div class="char ">
                        <h5 class="mt-3 mb-3">เปรียบเทียบจำนวนคน</h5>
                    </div>
                    <div class="char float-right">
                        
                    </div>
                </div>
                <table id="tb-data4" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนที่ลงทะเบียน</th>
                        <th scope="col">{{date1}}</th>
                        <th scope="col">{{date2}}</th>
                        <th scope="col">{{date3}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in facultyList2" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.f_student }} </td>
                            <td>
                                <span v-if="student1[index] < 0" class="text-danger">{{ student1[index] }}</span>
                                <span v-if="student1[index] > 0" class="text-primary">{{ student1[index] }}</span>
                                <span v-if="student1[index] == 0" class="text-success">{{ student1[index] }}</span>
                            </td>
                            <td>
                                <span v-if="student2[index] < 0" class="text-danger">{{ student2[index] }}</span>
                                <span v-if="student2[index] > 0" class="text-primary">{{ student2[index] }}</span>
                                <span v-if="student2[index] == 0" class="text-success">{{ student2[index] }}</span>
                            </td>
                            <td>
                                <span v-if="student3[index] < 0" class="text-danger">{{ student3[index] }}</span>
                                <span v-if="student3[index] > 0" class="text-primary">{{ student3[index] }}</span>
                                <span v-if="student3[index] == 0" class="text-success">{{ student3[index] }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/th'
import Swal from 'sweetalert2';
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelSelect } from 'vue-search-select';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Form',
  components: {
    DatePicker,
    ModelSelect,
    Header,
    Footer
  },
  data() {
    return {
      student: 0,
      minute: 0,
      second: 0,
      average: 0,
      date1: '12-01-2022',
      date2: '13-01-2022',
      date3: '14-01-2022',
      facultyList: '',
      facultyListSelect: [],
      facultyList2: '',
      selectFaculty: '',
      stateList: '',
      merchList: [],
      exportCompareList: [],
      time1: new Date(),
      date: '12-01-2022',
      compareList: '',
      day1: [],
      day2: [],
      day3: [],
      student1: [],
      student2: [],
      student3: [],
      json_fields: {
      "คณะ": "faculty",
      "เวลาเฉลี่ย คน/นาที": "result",
    },
    json_fields2:{
        "คณะ": "faculty",
        "day1": this.day1,
        "day2": this.day2,
        "day3": this.day3
    }
    }
  },
  mounted() {
      this.onInit();
  },
  methods: {
       disabledBeforeTodayAndAfterAWeek(date) {
        const today = new Date(2022,0,10);
        today.setHours(0, 0, 0, 0);

        return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },
      cal(){
          var dot = (this.second / 60).toFixed(2);
          var dots = (Number(this.minute) + Number(dot)).toFixed(2);
          this.average = (Number(this.student) / Number(dots)).toFixed(2);

          axios.post('/api/state', {
              date: this.time1,
              faculty: this.selectFaculty,
              student: this.student,
              minute: this.minute,
              secode: this.second,
              result: this.average
          }).then(res => {
              Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
              })
              this.student = 0;
              this.minute = 0;
              this.second = 0;
              this.average = 0;
              this.onInit();
          })
      },
      onInit(){
        this.facultyListSelect = [];
        axios.get('/api/faculty').then(res => {
            this.facultyList = res.data;

            res.data.forEach(element => {
                this.facultyListSelect.push({
                    value: element.f_name,
                    text: element.f_name
                })
            });

            $('#selectFaculty').text(this.facultyListSelect[0].text);
            $('#selectFaculty').val(this.facultyListSelect[0].value);

            axios.get(`/api/state/date/${this.date}`).then(res1 => {
                this.stateList = res1.data;
                this.onMerch();
            })
        })
      },
      onMerch(){
        let merch = this.facultyList.map((e,i)=>{
        let temp = this.stateList.find(element=> element.faculty === e.f_name)
        if(temp) {
            e.result = temp.result;
            e.s_id = temp._id;
            e.faculty = temp.faculty;
            e.student = temp.student;
            e.minute = temp.minute;
            e.secode = temp.secode;
            e.date = temp.date;
        }
          return e;
        })

        this.merchList = merch;
        $(document).ready(function() {
            $('#tb-data').DataTable({
                'pageLength': 25,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                "bDestroy": true,
                columnDefs: [
                    { orderable: false, targets: -1 }
                ],
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
        $(document).ready(function() {
            $('#tb-data21').DataTable({
                'pageLength': 25,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                "bDestroy": true,
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
        $(document).ready(function() {
            $('#tb-data2').DataTable({
                'pageLength': 25,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                "bDestroy": true,
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
      },
      onChangeDate(date){
            this.date = date;
            this.onInit();
      },
      getCompare(){
          this.day1 = [];
          this.day2 = [];
          this.day3 = [];
          axios.get('/api/faculty').then(res => {
            this.facultyList2 = res.data;
            for (let i = 0; i < this.facultyList2.length; i++) {
                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date1}`)
                    .then(res => {
                        if(res.data != null){
                            this.$set(this.day1, i, res.data.result);
                        } 
                })

                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date2}`)
                    .then(res => {
                        if(res.data != null){
                            this.$set(this.day2, i, res.data.result);
                        } 
                })

                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date3}`)
                    .then(res => {
                        if(res.data != null){
                            this.$set(this.day3, i, res.data.result);
                        } 
                })

            }
            // $(document).ready(function() {
            //     $('#tb-data3').DataTable({
            //         'pageLength': 25,
            //         "bDestroy": true,
            //         "language": {
            //         "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
            //         "zeroRecords": "ไม่พบข้อมูล!",
            //         "info": "หน้า _PAGE_ จาก _PAGES_",
            //         "infoEmpty": "No records available",
            //         "infoFiltered": "(filtered from _MAX_ total records)",
            //         "paginate": {
            //             "previous": "ก่อนหน้า",
            //             "next": "ต่อไป"
            //         },
            //         "search": "ค้นหา"
            //     }
            //     });
            // } );
        })
      },
      getCompareStudent(){
          this.student1 = [];
          this.student2 = [];
          this.student3 = [];
          axios.get('/api/faculty').then(res1 => {
            this.facultyList2 = res1.data;
            for (let i = 0; i < this.facultyList2.length; i++) {
                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date1}`)
                    .then(res => {
                        if(res.data != null){
                            console.log(res.data);
                            this.$set(this.student1, i, 
                                res.data.student - this.facultyList2[i].f_student
                            );
                        } 
                })

                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date2}`)
                    .then(res => {
                        if(res.data != null){
                            this.$set(this.student2, i, 
                                res.data.student - this.facultyList2[i].f_student
                            );
                        } 
                })

                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date3}`)
                    .then(res => {
                        if(res.data != null){
                            this.$set(this.student3, i, 
                                res.data.student - this.facultyList2[i].f_student
                            );
                        } 
                })

            }
        })
      },
      onDelete(id){
          Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.delete(`/api/state/${id}`).then(res => {
                            Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                            )
                            this.onInit();
                        });
                    }
                })
      },
      onUpdate(item){
        // $('#selectFaculty').text('');
        // $('#selectFaculty').val('');
        $('#selectFaculty').text(item.faculty);
        $('#selectFaculty').val(item.faculty);
        // this.selectFaculty = item.faculty;
        this.student = item.student;
        this.second = item.secode;
        this.minute = item.minute;
        this.time1 = item.date;
      }
  },
}
</script>

<style scoped>
.header-form{
    padding-left: 5%;
}
.container{
    padding: 5px;
}  

.form-control{
    max-width: 100px;
}

.col-faculty .form-control{
    min-width: 250px;
}

#head-content {
  display: flex;
}

.float-right {
  margin-left: auto;
}

.content{
    margin-bottom: 6%;
}
</style>
