<template>
  <div class="container-fluid">
      <Header />
      <div class="container mt-3">
         <div class="row">
            <input v-model="firstname" type="text" class="form-control" style="max-width: 250px;">
            <input v-model="lastname" type="text" class="form-control" style="max-width: 250px; margin-left:3%;">
            <button @click="onSearch()" class="btn btn-info" type="button" style="max-width: 150px; margin-left:3%;">ค้นหา</button>
            <button @click="onDetail('', 'external')" class="btn btn-outline-secondary" type="button" style="max-width: 150px; margin-left:3%;">บุคคลภายนอก</button>
            <button @click="onExport()" class="btn btn-outline-info" type="button" style="max-width: 150px; margin-left:3%;">Export</button>
            <!-- <input type="file" @change="onChange($event)"> -->
         </div>
         <h5 v-if="status" class="mt-3">ผลการค้นหา</h5>
         <table class="table table-hover mt-3" v-if="status">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ชื่อ - นามสกุล</th>
              <th scope="col">สังกัด</th>
              <th scope="col">หน่วยงาน</th>
              <th scope="col">จัดการข้อมูล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in personalList" :key="index">
              <th scope="row"> {{ index + 1 }} </th>
              <td> {{ item.prenameTha }} {{ item.firstNameTha }} {{ item.lastNameTha }} </td>
              <td> {{ item.faculty }} </td>
              <td> {{ item.department }} </td>
              <td> <button class="btn btn-sm btn-outline-info" @click="onDetail(item, 'mis')">จัดการข้อมูล</button> </td>
            </tr>
          </tbody>
        </table>
        <h5 class="mt-3">ข้อมูลในระบบ</h5>
         <table class="table table-hover mt-3" id="manage-card">
          <thead>
            <tr>
              <th scope="col" style="width: 5%;">#</th>
              <th scope="col" style="width: 25%;">ชื่อ - นามสกุล</th>
              <th scope="col" style="width: 35%;">ฝ่าย</th>
              <th scope="col">สีบัตร</th>
              <th scope="col">ลำดับ</th>
              <th scope="col">จัดการข้อมูล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in infoList" :key="index">
              <th scope="row"> {{ index + 1 }} </th>
              <td> {{ item.prename }} {{ item.fullname }} </td>
              <td> {{ item.faculty }} </td>
              <td> 
                <span v-if="item.type == 1">สีแดง</span>
                <span v-if="item.type == 2">สีชมพู</span>
                <span v-if="item.type == 3">สีเขียว</span>
                <span v-if="item.type == 4">สีเหลือง</span>
              </td>
              <td> {{ item.runnumber }} </td>
              <td>
                <button class="btn btn-sm btn-outline-info" @click="onDetail(item, 'db')">จัดการข้อมูล</button>
                <button @click="onDelete(item._id)" class="btn btn-outline-danger btn-sm btn-add ml-2"><i class="fas fa-trash-alt"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal" tabindex="-1" id="modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">จัดการข้อมูล</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h5 class="text-center mt-2" v-if="status == false" style="color: red;">ไม่พบข้อมูล!</h5><br>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">ลำดับ</label>
                </div>
                <div class="col-auto">
                  <input type="text" class="form-control" v-model="form.runnumber">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">คำนำหน้าชื่อ</label>
                </div>
                <div class="col-auto">
                  <input type="text" class="form-control" v-model="form.prename">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">ชื่อ - นามสกุล</label>
                </div>
                <div class="col-auto">
              <input type="text" class="form-control" v-model="form.fullname">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">หน้าที่</label>
                </div>
                <div class="col-auto">
              <input type="text" class="form-control" v-model="form.faculty">
                </div>
              </div>
              <label class="form-label mt-2">รูปถ่าย</label><br>
              <img v-if="form.image" width="90" height="90" :src="form.image" alt="avatar">
              <input type="file" id="getFile2" @change="onChange2($event)"><br><br>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">เลขบัตรประชาชน</label>
                </div>
                <div class="col-auto">
                  <input type="text" class="form-control" v-model="form.personalid">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">โทรศัพท์</label>
                </div>
                <div class="col-auto">
                  <input type="text" class="form-control" v-model="form.phone">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">อีเมล์</label>
                </div>
                <div class="col-auto">
                  <input type="text" class="form-control" v-model="form.email">
                </div>
              </div>
              <div class="row g-3 align-items-center">
                <div class="col-4 mt-1">
                  <label class="col-form-label">สีบัตร</label>
                </div>
                <div class="col-auto">
                  <select class="form-control" v-model="form.type">
                    <option value="1">สีแดง</option>
                    <option value="2">สีชมพู</option>
                    <option value="3">สีเขียว</option>
                    <option value="4">สีเหลือง</option>
                  </select>
                </div>
              </div>
              <img v-if="form.image" :src="form.image" alt="img" width="150" height="150">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">ปิด</button>
              <button type="button" class="btn btn-primary" @click="onAdd()">เพิ่มข้อมูล</button>
            </div>
          </div>
        </div>
      </div>
  </div> 
</template>

<script>
import Header from '@/components/Card/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

export default {
  name: "home",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.form.faculty = this.$route.params.faculty;
    axios.get('/api/user').then(res => { 
        this.form.permission = res.data.permission;
    });
    this.onGetInfo();
  },
  methods: {
      onSearch(){
          axios.post('/api/info', { firstname: this.firstname, lastname: this.lastname }).then(res => {
              if (res.data.status == true) {
                this.personalList = res.data.data;
                this.status = res.data.status;
              } else {
                this.status = res.data.status;
                $('#modal').modal('show');
              }
          })
      },
      onExport(){
        const dataWS = XLSX.utils.json_to_sheet(this.infoList)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb,'export.xlsx')
      },
      onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      var data = '';
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          data = XLSX.utils.sheet_to_json(ws, { raw:true });
          var i = 0;

          console.log(data.length);

          while (i < data.length) {
            (function(i) {
              setTimeout(function() {
                axios.post('/api/info', { firstname: data[i].fullname.split(" ")[0], lastname: data[i].fullname.split(" ")[1] }).then(res => {
                  if (res.data.status == true) {
                    axios.post('/api/info/detail', { cmuitaccount: res.data.data[0].emailCMU }).then(res2 => {
                        axios.put('/api/cardlist/'+ data[i]._id, { image: res2.data.image, phone: res2.data.phone }).then(res => {
                        })
                        // axios.put('/api/cardlist/'+ data[i]._id, { personalid: res2.data.personalid }).then(res => {
                        // })

                        console.log(i, 'เจอ :' + data[i].fullname);

                      })
                  }else{
                    console.log(i, 'ไม่เจอ :' + data[i].fullname);
                  }
                })

                if (i == data.length - 1) {
                  alert("success")
                }
              }, 8000 * i)
            })(i++)
          }
          
        }

        reader.readAsBinaryString(this.file);
      }
    },
      onChange2(event) {
      this.file2 = event.target.files ? event.target.files[0] : null;
      if (this.file2) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file2)
        reader.onload = () => {
            this.form.image = reader.result;
        };
      }
    },
      onDetail(item, type){
        this.status = true;
        this.form.prename = '';
        this.form.fullname = '';
        this.form.faculty = '';
        this.form.email = '';
        this.form.personalid = '';
        this.form.phone = '';
        this.form.image = '';
        this.form.department = '';
        this.form.runnumber = '';
        this.form.id = null;

        if (type == 'mis') {
          axios.post('/api/info/detail', { cmuitaccount: item.emailCMU }).then(res => {

          this.form.prename = item.prenameTha;
          this.form.fullname = item.firstNameTha + ' ' + item.lastNameTha;
          this.form.email = item.emailCMU;
          this.form.personalid = res.data.personalid;
          this.form.phone = res.data.phone;
          this.form.image = res.data.image;

          $('#modal').modal('show');
        })
        } else if(type == 'db') {
          this.form.prename = item.prename;
          this.form.fullname = item.fullname;
          this.form.email = item.email;
          this.form.personalid = item.personalid;
          this.form.phone = item.phone;
          this.form.image = item.image;
          this.form.type = item.type;
          this.form.id = item._id;
          this.form.runnumber = item.runnumber;
          this.form.faculty = item.faculty;

          $('#modal').modal('show');
        }
        else{
          this.status = true;
          $('#modal').modal('show');
        }

      },
      onAdd(){
        if (this.form.type == 2) {
          this.form.department = 'ผู้เข้าร่วมพิธีฯ'
        } else {
          this.form.department = 'ผู้ปฏิบัติหน้าที่'
        }

        if (this.form.id == null) {
          axios.post('/api/cardlist', this.form).then(res => {
            if(res.data == 'repeat'){
              Swal.fire({
                icon: 'error',
                title: 'มีข้อมูลในระบบแล้ว!',
                showConfirmButton: false,
                timer: 1500
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: 'บันทึกสำเร็จ',
                showConfirmButton: false,
                timer: 1500
              })
              this.onGetInfo();
              $('#modal').modal('hide');
            }
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'มีข้อมูลในระบบแล้ว!',
              showConfirmButton: false,
              timer: 1500
            })
          })
        } else {
          axios.put('/api/cardlist/'+ this.form.id, this.form).then(res => {
            if(res.data == 'repeat'){
              Swal.fire({
                icon: 'error',
                title: 'มีข้อมูลในระบบแล้ว!',
                showConfirmButton: false,
                timer: 1500
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: 'บันทึกสำเร็จ',
                showConfirmButton: false,
                timer: 1500
              })
              this.onGetInfo();
              $('#modal').modal('hide');
            }
          })
        }
      },
      onGetInfo(){
        axios.get('/api/cardlist/info/').then(res => {
          this.infoList = res.data;
          $(document).ready(function() {
                $('#manage-card').DataTable({
                    'pageLength': 25,
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    columnDefs: [
                        { orderable: false, targets: -1 }
                    ],
                    "language": {
                        "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                        "zeroRecords": "ไม่พบข้อมูล!",
                        "info": "หน้า _PAGE_ จาก _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "paginate": {
                            "previous": "ก่อนหน้า",
                            "next": "ต่อไป"
                        },
                        "search": "ค้นหา"
                    },
                     "bDestroy": true
                });
            } );
        })
      },
      onDelete(id){
        Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete('/api/cardlist/'+ id).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'ลบข้อมูลสำเร็จ',
              showConfirmButton: false,
              timer: 1500
            })
            this.onGetInfo();
          })
        }
      })
      }
  },
  data() {
      return {
          firstname: '',
          lastname: '',
          personalList: '',
          status: false,
          form: {
            prename: '',
            fullname: '',
            email: '',
            personalid: '',
            phone: '',
            image: '',
            type: '',
            permission: 'admin',
            cardtype: 'D',
            faculty: '',
            id: null,
            department: '',
            date: '๒๕ มี.ค.๖๕',
            runnumber: ''
          },
          infoList: ''
      }
  },
};
</script>

<style scoped>
    .ml-2{
      margin-left: 2%;
    }
</style>
