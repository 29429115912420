<template>
  <div class="container-fluid">
  <Header />
  <div class="container mt-5 content">
    <div class="modal fade" id="facultyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Faculty Form</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">คณะฯ</label>
                <input type="text" class="form-control" placeholder="input faculty" v-model="f_name">
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">จำนวนนักศึกษา</label>
                <input type="text" class="form-control" placeholder="input faculty" v-model="f_student">
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="onSave()" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ufacultyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Faculty Form</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">คณะ</label>
                <input type="text" class="form-control" placeholder="input faculty" v-model="u_f_name.f_name">
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">จำนวนนักศึกษา</label>
                <input type="text" class="form-control" placeholder="input faculty" v-model="u_f_name.f_student">
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">ปิด</button>
        <button type="button" class="btn btn-primary" @click="onSave()" data-bs-dismiss="modal">บันทึก</button>
      </div>
    </div>
  </div>
</div>
      
      <button class="btn btn-outline-info btn-add" @click="onAdd()" data-bs-toggle="modal" data-bs-target="#facultyModal" type="button">เพิ่ม</button>

    <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">คณะ</th>
      <th scope="col">จำนวนนักศึกษา</th>
      <th scope="col" class="text-center">ลำดับการเข้ารับ</th>
      <th scope="col" class="text-center">จัดการข้อมูล</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in facultyList" :key="index">
      <th scope="row">{{ index + 1 }}</th>
      <td> {{ item.f_name }} </td>
      <td> {{ item.f_student }} </td>
      <td class="text-center">
        <div v-if="index == 0">
          <button type="button" @click="onDownOrder(index, index+1)" class="btn"><i class="fas fa-angle-down"></i></button>
        </div>
        <div v-if="index > 0 && index < length">
          <button type="button" @click="onDownOrder(index, index+1)" class="btn"><i class="fas fa-angle-down"></i></button>
          <button type="button" @click="onUpOrder(index, index-1)" class="btn"><i class="fas fa-angle-up"></i></button>
        </div>
        <div v-if="index == length">
          <button type="button" @click="onUpOrder(index, index-1)" class="btn"><i class="fas fa-angle-up"></i></button>
        </div>
      </td>
      <td class="text-center">
        <button type="button" @click="onUpdate(item)" data-bs-toggle="modal" data-bs-target="#ufacultyModal" class="btn btn-outline-info"><i class="fas fa-pencil-alt"></i></button>
        <button type="button" @click="onDelete(item._id)" class="btn btn-outline-danger"><i class="far fa-trash-alt"></i></button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
  <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      facultyList: '',
      f_name: '',
      f_student: '',
      length: '',
      u_f_name: '',
      u_f_student: '',
      addOrUpdate: ''
    }
  },
  mounted() {
      this.onInit();
  },
  methods: {
    onSave(){
      if (this.addOrUpdate == 'add') {
        if (this.f_name != '' && this.f_student != '') {
          axios.post('/api/faculty', {
            f_name: this.f_name,
            f_student: this.f_student
          }).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.f_name = '';
            this.onInit();
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'please input data!',
          })
        }
      }else{
        if (this.u_f_name.f_name != '') {
          axios.put(`/api/faculty/${this.u_f_name._id}`, this.u_f_name).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.u_f_name = '';
            this.onInit();
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'please input data!',
          })
        }
      }
    },
    onInit(){
      axios.get('/api/faculty').then(res => {
          this.facultyList = res.data;
          this.length = res.data.length - 1;
      })
    },
    onDelete(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/faculty/${id}`).then(res => {
              Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            this.onInit();
          })
        }
      })
    },
    onUpOrder(order, u_order){
      var c_order = this.facultyList[order];
      var up_order = this.facultyList[u_order];

      axios.put(`/api/faculty/order/${c_order._id}`, {
          c_order: c_order.order,
          u_id: up_order._id,
          u_order: up_order.order 
        }).then(res => {
            this.onInit();
        })
    },
    onDownOrder(order, d_order){
      var c_order = this.facultyList[order];
      var down_order = this.facultyList[d_order];

      axios.put(`/api/faculty/order/${c_order._id}`, {
          c_order: c_order.order,
          u_id: down_order._id,
          u_order: down_order.order 
        }).then(res => {
            this.onInit();
        })
    },
    onUpdate(item){
      this.addOrUpdate = 'update';
      this.u_f_name = item;
    },
    onAdd(){
      this.addOrUpdate = 'add';
    }
  },
}
</script>

<style scoped>
 .btn-add{
     float: right;
 }
 .content{
    margin-bottom: 6%;
}
</style>
