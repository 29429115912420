<template>
  <div class="form container mt-5">
    <div class="container tabs">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" href="#date_h1" @click="onChangeDate(date1)" data-bs-toggle="tab"> {{ date1 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date_h2" @click="onChangeDate(date2)" data-bs-toggle="tab"> {{ date2 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date_h21" @click="onChangeDate(date3)" data-bs-toggle="tab"> {{ date3 }} </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#date_h3" @click="getCompare()" data-bs-toggle="tab"> เปรียบเทียบข้อมูล </a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="date_h1" class="tab-pane active">
                <h5 class="mt-3 mb-3">{{date1}}</h5>
                <table id="tb-data_h1" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน/นาที</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="date_h2" class="tab-pane">
                <h5 class="mt-3 mb-3">{{date2}}</h5>
                <table id="tb-data2_h2" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน/นาที</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="date_h21" class="tab-pane">
                <h5 class="mt-3 mb-3">{{date3}}</h5>
                <table id="tb-data2_h21" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">จำนวนคน/นาที</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in merchList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ item.result }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
             <div id="date_h3" class="tab-pane">
                <h5 class="mt-3 mb-3">เปรียบเทียบข้อมูล</h5>
                <table id="tb-data3_h3" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">คณะ</th>
                        <th scope="col">{{date1}}</th>
                        <th scope="col">{{date2}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in facultyList2" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.f_name }} </td>
                            <td> {{ day1[index] }} </td>
                            <td> {{ day2[index] }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/th'
import Swal from 'sweetalert2';
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelSelect } from 'vue-search-select';

export default {
  name: 'Form',
  components: {
    DatePicker,
    ModelSelect
  },
  data() {
    return {
      student: 0,
      minute: 0,
      second: 0,
      average: 0,
      date1: '12-01-2022',
      date2: '13-01-2022',
      date3: '14-01-2022',
      facultyList: '',
      facultyListSelect: [],
      facultyList2: '',
      selectFaculty: '',
      stateList: '',
      merchList: '',
      time1: new Date(),
      date: '12-01-2022',
      compareList: '',
      day1: [],
      day2: [],
    }
  },
  mounted() {
      this.onInit();
  },
  methods: {
       disabledBeforeTodayAndAfterAWeek(date) {
        const today = new Date(2022,0,10);
        today.setHours(0, 0, 0, 0);

        return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },
      cal(){
          var dot = (this.second / 60).toFixed(2);
          var dots = (Number(this.minute) + Number(dot)).toFixed(2);
          this.average = (Number(this.student) / Number(dots)).toFixed(2);

          axios.post('/api/state', {
              date: this.time1,
              faculty: this.selectFaculty,
              student: this.student,
              minute: this.minute,
              secode: this.second,
              result: this.average
          }).then(res => {
              Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
              })
              this.student = 0;
              this.minute = 0;
              this.secode = 0;
              this.average = 0;
              this.onInit();
          })
      },
      onInit(){
        this.facultyListSelect = [];
        axios.get('/api/faculty').then(res => {
            this.facultyList = res.data;
            this.selectFaculty = res.data[0].f_name;

            res.data.forEach(element => {
                this.facultyListSelect.push({
                    value: element.f_name,
                    text: element.f_name
                })
            });

            axios.get(`/api/state/date/${this.date}`).then(res1 => {
                this.stateList = res1.data;
                this.onMerch();
            })
        })
      },
      onMerch(){
        let merch = this.facultyList.map((e,i)=>{
        let temp = this.stateList.find(element=> element.faculty === e.f_name)
        if(temp) {
            e.result = temp.result;
        }
          return e;
        })

        this.merchList = merch;
        $(document).ready(function() {
            $('#tb-data_h1').DataTable({
                'pageLength': 25,
                "bDestroy": true,
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
        $(document).ready(function() {
            $('#tb-data2_h21').DataTable({
                'pageLength': 25,
                "bDestroy": true,
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
        $(document).ready(function() {
            $('#tb-data2_h2').DataTable({
                'pageLength': 25,
                "bDestroy": true,
                "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
            });
        } );
      },
      onChangeDate(date){
            this.date = date;
            this.onInit();
      },
      async getCompare(){
          this.day1 = [];
          this.day2 = [];
          axios.get('/api/faculty').then(res => {
            this.facultyList2 = res.data;
            for (let i = 0; i < this.facultyList2.length; i++) {
                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date1}`)
                    .then(res => {
                        if(res.data != null){
                            this.day1.push(res.data.result);
                        } 
                })

                axios.get(`/api/state/${this.facultyList2[i].f_name}/${this.date2}`)
                    .then(res => {
                        if(res.data != null){
                            this.day2.push(res.data.result);
                        } 
                })
            }
            $(document).ready(function() {
                $('#tb-data3_h3').DataTable({
                    'pageLength': 25,
                    "bDestroy": true,
                    "language": {
                    "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                    "zeroRecords": "ไม่พบข้อมูล!",
                    "info": "หน้า _PAGE_ จาก _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "paginate": {
                        "previous": "ก่อนหน้า",
                        "next": "ต่อไป"
                    },
                    "search": "ค้นหา"
                }
                });
            } );
        })
      }
  },
}
</script>

<style scoped>
.container{
    padding: 5px;
}  
.form-control{
    max-width: 100px;
}
.col-faculty .form-control{
    min-width: 150px;
}
</style>
