<template>
  <div class="container-fluid">
      <Header />
      <div class="container mt-5">
          <h3>ประเภทบัตร</h3>
          <div class="row card-type mt-3">
              <div class="col-md-4" @click="onClickCard(1)">
                  <img src="@/assets/img/temp1.png" alt="card">
              </div>
              <!-- <div class="col-md-4" @click="onClickCard(2)">
                  <img src="@/assets/img/temp2.png" alt="card">
              </div>
              <div class="col-md-4" @click="onClickCard(3)">
                  <img src="@/assets/img/temp3.png" alt="card">
              </div>
              <div class="col-md-4" @click="onClickCard(4)">
                  <img src="@/assets/img/temp4.png" alt="card">
              </div> -->
          </div>
      </div>
      <Footer />
  </div> 
</template>

<script>
import Axios from 'axios';
import Header from '@/components/Card/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "home",
  components: {
    Header,
    Footer
  },
  methods: {
      onClickCard(id){
          this.$router.push({
              name: 'card',
              params:{
                  type: id
              }
          })
      }
  },
};
</script>

<style scoped>
    .container{
        margin-bottom: 5%;
    }
    .card-type img{
        width: 100%;
        flex-shrink: 0;
        border: calc(8px + 0.2vw) solid transparent;
        background-origin: border-box;
        background-clip: content-box, border-box;
        background-size: cover;
        box-sizing: border-box;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    }
    .card-type img:hover{
        box-shadow: 0 10px 35px 0px rgba(0,0,0,.1);
    }
    .col-md-4{
        padding: 10px;
        cursor: pointer;
    }
</style>
