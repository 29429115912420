<template>
  <header>
    <div class="px-3 py-2 bg-light text-black">
      <div class="container mt-2">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/cardpage" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
            <img src="@/assets/img/logo.png" alt="logo">
          </a>

          <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <li>
              <a v-if="login" href="/cardpage" class="nav-link text-black">
                <div class="icon text-center">
                  <i class="fas fa-home"></i>
                </div>
                หน้าหลัก
              </a>
            </li>
            <!-- <li>
              <a v-if="login" href="/department" class="nav-link text-black">
                <div class="icon text-center">
                  <i class="fas fa-address-book"></i>
                </div>
                จัดการข้อมูล
              </a>
            </li> -->
            <li>
              <button v-if="login" @click="onLogout()" class="btn nav-link text-black">
                <div class="icon text-center">
                  <i class="far fa-share-square"></i>
                </div>
                ออกจากระบบ
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Form',
  components: {
    
  },
  data() {
    return {
      login: false
    }
  },
  mounted() {
    axios.get('/api/user')
        .then(res => {
            if (res.data != 'denied') {
              this.login = true;
            }else{
              this.login = false;
            }
        })
  },
  methods: {
    onLogout(){
      axios.delete('/api/user')
        .then(res => {
            this.$router.push({ name: 'login' });
        })
    }
  },
}
</script>

<style scoped>
  .icon i{
    font-size: 20px;
  }
</style>
