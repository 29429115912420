<template>
  <div class="home container-fluid">
    
    <header>
      <div class="px-3 py-2 bg-light text-black">
        <div class="container">
          <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
              <img src="@/assets/img/logo.png" alt="logo">
            </a>

            <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <li>
                <a data-bs-toggle="modal" data-bs-target="#contactModal" type="button" class="nav-link text-black">
                  <!-- <div class="icon text-center">
                    <i class="fas fa-phone"></i>
                  </div> -->
                  ติดต่อสอบถาม
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <button type="button" data-bs-toggle="modal" data-bs-target="#contactModal" class="btn btn-info" style="border-radius: 50%; position: fixed; bottom: 5%; right: 3%;"><i style="color: #fff;" class="fas fa-phone"></i></button>

    <div class="container table-content">
      <!-- <h1 @click="exportToPDF()">HOME</h1>  -->
      <div id="head-content">
                    <div class="char">
                        <h5 class="mt-3 mb-3">รายชื่อ</h5>
                    </div>
                    <div class="char float-right">
                      <!-- <button class="btn btn-outline-primary btn-add" @click="onAdd('add')" data-bs-toggle="modal" data-bs-target="#cardModalex" type="button">เพิ่มข้อมูล <i class="far fa-plus-square"></i></button> -->
                    </div>
      </div>

      <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">ติดต่อสอบถาม</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <img src="@/assets/img/cadoop.png" alt="logo" width="180" height="70">
              </div>
              <div class="text-left mt-3">
                <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ด้วยมหาวิทยาลัยเชียงใหม่ จัดให้มี พิธีพระราชทานปริญญาบัตร ครั้งที่ 56 ในวันที่ 25 มีนาคม 2565
                ณ หอประชุมมหาวิทยาลัยเชียงใหม่
                เพื่อให้การดำเนินงานในพิธีฯ เป็นไปด้วยความเรียบร้อย มณฑลทหารบกที่ 33 จึงมีประกาศการจัดทำบัตรบุคคล 4 สี สำหรับผู้ร่วมพิธีและผู้ปฏิบัติหน้าที่ในหมายกำหนดการเสด็จฯ
                </p>
              </div>
              <div class="contact">
                <p><strong>กรณีต้องการเปลี่ยนแปลงข้อมูลประเภทสีบัตร ติดต่อเจ้าหน้าที่กองบริหารงานกลาง ได้ที่</strong></p>
                <ul>
                  <li style="margin-left:7%;">เบอร์ 0 5394 3014 นางสรรชนา เม่งเอียด (คุณตู่)</li>
                  <li style="margin-left:7%;">เบอร์ 0 5394 3013 นางขวัญชีวา กุณา (คุณขวัญ)</li>
                  <li style="margin-left:7%;">เบอร์ 0 5394 3011 นางสาวอนามิกา พิมพา (คุณเฟิร์น)</li>
                </ul>
              </div>
            </div>
            <div class="text-center mb-3">
              <a target="_blank" href="https://drive.google.com/file/d/1xPRjd7odA8Zym47euXTniX1YzMmmTMxG/view?usp=sharing">รายละเอียดการกำหนดประเภทสีบัตรบุคคล</a>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

                <table id="tb-cardex" class="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">ชื่อ-นามสกุล</th>
                        <th scope="col">สีบัตร</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cardList" :key="index">
                            <th scope="row"> {{ index + 1 }} </th>
                            <td> {{ item.prename }} {{ item.fullname }} </td>
                            <td>
                                <div v-if="item.type == 1" style="background-color: red; width: 100px;" class="text-center"><span style="color: #fff;">สีแดง</span></div>
                                <div v-if="item.type == 2" style="background-color: #FF33F9; width: 100px;" class="text-center"><span>สีชมพู</span></div>
                                <div v-if="item.type == 3" style="background-color: green; width: 100px;" class="text-center"><span style="color: #fff;">สีเขียว</span></div>
                                <div v-if="item.type == 4" style="background-color: yellow; width: 100px;" class="text-center"><span>สีเหลือง</span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
    </div>

  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import Header from '@/components/Card/Header';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      data: '',
      dataimage: [],
      permission: '',
      type: '',
      fileupload: '',
      progress: 'width: 0%',
      p_status: false,
      cardList: '',
      form: {
        department: '',
        prename: '',
        fullname: '',
        personalid: '',
        date: '',
        type: '',
        image: ''
      },
      status: '',
      typeStatus: 1
    }
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit(){
          axios.get(`/api/cardlist/external`).then(res => {
            this.cardList = res.data;
            $(document).ready(function() {
                $('#tb-cardex').DataTable({
                    'pageLength': 25,
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    columnDefs: [
                        { orderable: false, targets: -1 }
                    ],
                    "language": {
                        "lengthMenu": "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                        "zeroRecords": "ไม่พบข้อมูล!",
                        "info": "หน้า _PAGE_ จาก _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "paginate": {
                            "previous": "ก่อนหน้า",
                            "next": "ต่อไป"
                        },
                        "search": "ค้นหา"
                    },
                     "bDestroy": true
                });
            } );
          });

    },
    onChange2(event) {
      this.file2 = event.target.files ? event.target.files[0] : null;
      if (this.file2) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file2)
        reader.onload = () => {
            this.form.image = reader.result;
        };
      }
    },
    onAdd(status){
      this.status = status;
    },
    onTypeChange(item){
      this.typeStatus = item.value;
    },
    onSave(){
      if (this.status == 'add') {
        this.form.date = "๒๕ มี.ค.๖๕";
        if (this.form.type == 2) {
            this.form.department = 'ผู้เข้าร่วมพิธีฯ'
        } else {
            this.form.department = 'ผู้ปฏิบัติหน้าที่'
        }

        if (this.form.type == 1 || this.form.type == 2) {
          if (this.form.fullname != '' && this.form.type != '' && this.form.personalid != '' && this.form.image != '') {
          axios.post('/api/cardlist', this.form).then(res => {
              Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                showConfirmButton: false,
                timer: 1500
              })
              $('#tb-cardex').DataTable().destroy();
              this.onInit();   
              this.form.prename = '';
              this.form.fullname = '';
              this.form.personalid = '';
              this.form.image = '';           
          })
        } else {
          if (this.form.fullname != '' && this.form.type != '' && this.form.personalid != '') {
            axios.post('/api/cardlist', this.form).then(res => {
                Swal.fire({
                  icon: 'success',
                  title: 'บันทึกข้อมูลสำเร็จ',
                  showConfirmButton: false,
                  timer: 1500
                })
                $('#tb-cardex').DataTable().destroy();
                this.onInit();   
                this.form.prename = '';
                this.form.fullname = '';
                this.form.personalid = '';
                this.form.image = '';           
            })
          }
        }

        
        } else {
          Swal.fire({
                icon: 'error',
                title: 'กรุณากรอกข้อมูลให้ครบ',
                showConfirmButton: false,
                timer: 1500
          })
        }
      } 
    },
  },
}
</script>

<style scoped>
  .table-content{
    padding: 5px;
    margin-bottom: 5%;
  }
  .content {
    position: relative;
    text-align: center;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #head-content {
  display: flex;
}

.float-right {
  margin-left: auto;
}

.btn-add{
  margin-left: 5px;
}

</style>
